<template>
  <div class="line-progress-container">
    <div class="line-progress-slide after" :class="{'border':after == 100}" :style="{ width: after + '%' }"></div>
    <div
      v-if="before&&after!=before"
      class="line-progress-slide before"
      :style="{ width: before + '%' }" :class="{'border':before == 100}"
    >
      <span class="text-before">{{before + '%'}}</span>
    </div>
    <span class="text-after">{{after + '%'}}</span>
  </div>
</template>

<script>
export default {
  props: {
    before: Number,
    after: Number,
  },
}
</script>

<style>
.line-progress-container {
  display: inline-block;
  position: relative;
  width: 186px;
  height: 20px;
  margin-left: 40px;
  background: #f0f0f0;
  border-radius: 10px;
  vertical-align: text-top;
}
.line-progress-slide {
  position: absolute;
  height: 20px;
  border-radius: 10px 0 0 10px;
}
.before {
  position: relative;
  background-color: #7093ef;
}
.after {
  background-color: #0148ff;
}
.text-before {
  position: absolute;
  right: 0;
  bottom: -24px;
  font-size: 16px;
  color: #d1ddff;
}
.text-after {
  position: absolute;
  top: 0;
  right: -60px;
  line-height: 20px;
  font-size: 20px;
  color: #0148ff;
}
.border{
  border-radius: 10px;

}
</style>
