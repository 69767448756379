<template>
  <div class="tab-tree">
    <div class="content">
      <div class="current-name">
        <latex :value="currentChildren.label"></latex>
      </div>
      <div class="ul">
        <div
          v-for="(item, index) in currentChildren.children"
          :key="index"
          class="li"
          :class="colorNode[item.id] ? colorNode[item.id].className : ''"
        >
          <latex class="leaf" :value="item.label"></latex>
          <template v-if="colorNode[item.id] && colorNode[item.id].percent >= 0">
            <v-progress-circular
              :value="colorNode[item.id].percent"
              :color="colorNode[item.id].color"
              width="2"
              rotate="-90"
            >{{ colorNode[item.id].percent }}%</v-progress-circular>
            <img
              v-if="float && colorNode[item.id].imageUrl"
              class="image"
              :src="colorNode[item.id].imageUrl"
            />
          </template>
        </div>
      </div>
      <div class="tab-placeholder"></div>
    </div>
    <v-tabs center-active show-arrows v-model="current">
      <v-tab v-for="(item, index) in treeData" :key="index">{{ item.label }}</v-tab>
    </v-tabs>
  </div>
</template>
<script>
export default {
  props: {
    treeData: Array,
    controlRate: Object,
    float: Boolean,
  },
  data() {
    return {
      current: 0,
      colorNode: {},
    }
  },
  computed: {
    currentChildren() {
      // console.log(this.treeData[this.current])
      return this.treeData[this.current] ? this.treeData[this.current] : {}
    },
  },
  watch: {
    controlRate: {
      handler(val) {
        if (val) {
          for (let i in val) {
            const { nowTotalNum, nowTrueNum, pastTotalNum, pastTrueNum } =
              val[i]
            let color = ''
            let className = null
            let imageUrl = null
            let percent = -1
            if (nowTotalNum) {
              percent = parseInt((nowTrueNum * 100) / nowTotalNum)
              if (percent >= 50) {
                color = '#00c800'
                className = 'li-green'
              } else {
                color = '#ff3838'
                className = 'li-red'
              }
              // 这次涨幅
              if (pastTotalNum) {
                const pastPercent = parseInt((pastTrueNum * 100) / pastTotalNum)
                // console.log({ percent, pastPercent })
                if (percent != pastPercent) {
                  imageUrl =
                    percent - pastPercent > 0
                      ? require('@/assets/images/report/treeUp.png')
                      : require('@/assets/images/report/treeDown.png')
                }
              }
            } else {
              color = 'rgb(153, 153, 153)'
            }
            this.$set(this.colorNode, i, {
              percent,
              color,
              className,
              imageUrl,
            })
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    changeCurrent(index) {
      this.current = index
    },
  },
}
</script>
<style lang="scss" scoped>
.tab-tree {
  height: 100%;
  overflow: auto;
}
.tab-bar {
  padding: 30px 0 20px 0;
  border-bottom: 1px solid #eee;
  white-space: nowrap;
}
.tab-item {
  display: inline-block;
  height: 48px;
  padding: 0 12px;
  margin-right: 16px;
  font-size: 22px;
  color: #999999;
  line-height: 48px;
  &.active {
    color: #fff;
    background: #008df4;
    box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.11);
    border-radius: 24px;
  }
}
.current-name {
  display: inline-block;
  position: relative;
  text-align: center;
  min-width: 120px;
  height: 36px;
  padding: 4px 8px;
  margin-top: 20px;
  line-height: 28px;
  font-size: 18px;
  border-radius: 4px;
  border: 1px dashed #999999;
  color: #999999;
}
.icon-tree-node {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0px;
  top: 18px;
}

.legend-container {
  position: absolute;
  top: 10px;
  right: 0px;
  font-size: 22px;
  font-weight: 300;
  color: #666666;
  .legend {
    position: relative;
    display: inline-block;
    margin-left: 50px;
    &-success::before {
      position: absolute;
      left: -26px;
      width: 12px;
      height: 12px;
      margin-top: 10px;
      background: #6fe0d7;
      content: "";
      border-radius: 50%;
    }

    &-danger::before {
      position: absolute;
      left: -26px;
      width: 12px;
      height: 12px;
      margin-top: 10px;
      background: #ff7a45;
      content: "";
      border-radius: 50%;
    }
  }
}
.ul {
  position: relative;
  margin-left: 40px;
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: -30px;
    height: 100%;
    border-left: 1px solid #eee;
  }
}
.li {
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 10px;
  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 20px);
    left: -30px;
    width: 30px;
    height: 22px;
    border-radius: 0 0 0 8px;
    border: none;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  &:last-of-type::after {
    content: "";
    position: absolute;
    left: -30px;
    bottom: 0;
    height: calc(50%);
    border-radius: 0 0 0 8px;
    border: none;
    border-left: 1px solid #fff;
  }

  &-red {
    .leaf {
      color: #ff3838;
      background-color: #ffebeb;
      border-color: #ff3838;
    }
    .v-progress-circular {
      color: #ff3838;
      background-color: #ffebeb;
    }
  }
  &-green {
    .leaf {
      color: #00c800;
      background-color: #edf9e8;
      border-color: #00c800;
    }
    .v-progress-circular {
      color: #52c41a;
      background-color: #edf9e8;
    }
  }
}
.leaf {
  position: relative;
  display: inline-block;
  min-width: 120px;
  max-width: calc(100% - 120px);
  padding: 4px 16px;
  margin-top: 6px;
  font-size: 18px;
  line-height: 28px;
  border: 1px dashed #999999;
  color: #999999;
  background-color: #f4f4f4;
  border-radius: 8px;
}
.v-progress-circular {
  margin: 8px 10px 2px 10px;
  background-color: #eee;
  border-radius: 50%;
  font-size: 10px;
  vertical-align: top;

  ::v-deep .v-progress-circular__underlay {
    stroke: none;
  }
}
.tab-placeholder {
  height: 40px;
}
.v-tabs {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 20px;
  border-top: 1px solid #eee;
  border-radius: 0 0 32px 32px;
  overflow: hidden;
}
.image {
  width: 10px;
  height: 30px;
}
</style>